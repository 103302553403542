<template>
  <div id="remittance" class="template-1">
    <header class="page-header container" style="padding-bottom: 35px!important">
      <h1>{{ translations.tcE503MCheckAndCashBulk || 'Multiple Camp Remittance (Form E503M)' }}</h1>
    </header>
    <div v-if="!allowPage">
      <security-banner :i18n="translations.components"></security-banner>
    </div>
    <div v-if="allowPage">
      <div v-if="showNoBalanceMsg" class="container">
        <NoBankDetails customMsg="a remittance"></NoBankDetails>
      </div>
      <div v-else class="container">
        <b-row>
          <b-col sm="3"></b-col>
          <b-col sm="4">
            <h6>{{ translations.tcRemittedBy }} {{ campLabel }}</h6>
          </b-col>
          <b-col sm="2">
            <h6 class="remittance-date">{{ translations.tcDate }} {{ todaysDate }}</h6>
          </b-col>
          <b-col sm="3"></b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <b-col sm="12">
              <b-alert
                :show="dismissCountDown"
                dismissible
                :variant="alertVariant"
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
                >{{ alertMessage }}</b-alert
              >
            </b-col>
          </b-col>
        </b-row>
        <b-row style="margin-top:50px;">
          <b-col sm="12" class="instruction" style="text-align: left;">
            <div class="numberCircle">1</div>
            <div class="instruction-text">{{ translations.tcSelectOfferingType }}</div>
          </b-col>
        </b-row>
        <b-row>
          <b-form-group id="first-fund-group">
            <b-form-radio-group id="radio-group-1" v-model="fundTypeSelected" name="radio-sub-component">
              <div v-for="(item, idx) in fundTypeOptions" :key="idx">
                <b-form-radio name="some-radios" :id="item.value" :value="item"
                  ><span v-html="translations[item.text]"></span
                ></b-form-radio>
              </div>
            </b-form-radio-group>
          </b-form-group>
          <b-form-group id="second-fund-group" :label="translations.tcStateConvention">
            <b-form-radio-group id="radio-group-2" v-model="fundTypeSelected" name="radio-sub-component">
              <div class="state-convention">
                <div v-for="(item, idx) in stateConFundTypeOptions" :key="idx">
                  <b-form-radio name="some-radios" :id="item.value" :value="item"
                    ><span v-html="translations[item.text]"></span
                  ></b-form-radio>
                </div>
              </div>
            </b-form-radio-group>
          </b-form-group>
        </b-row>
        <hr />
        <b-row>
          <b-col sm="4">
            <b-row style="margin-top:50px; text-align:center;">
              <b-col sm="12" class="instruction" style="margin-bottom:0">
                <div class="numberCircle">2</div>
                <div class="instruction-text">{{ translations.tcSelectOneOrMoreCamps }}</div>
                <b-form-group :label="translations.tcSortListBy" style="font-size:14px;" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    id="btn-radios-3"
                    class="sortBy"
                    button-variant="outline-light"
                    size="sm"
                    v-model="form.sortBy"
                    :options="optionsSortTypes"
                    :aria-describedby="ariaDescribedby"
                    name="radios-btn-default"
                    buttons
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-select
              :options="campListSelect"
              :select-size="8"
              @change="handleCampSelect($event)"
              v-model="selectedCamp"
            >
            </b-form-select>
          </b-col>
          <b-col sm="8">
            <b-row style="margin-top:50px;">
              <b-col sm="12" class="instruction">
                <div class="numberCircle">3</div>
                <div class="instruction-text">{{ translations.tcEnterAmountForEachCamp }}</div>
              </b-col>
            </b-row>
            <b-table
              class="selected-camps"
              striped
              hover
              :fields="tableFields"
              :items="selectedCampsForFunding"
              show-empty
              :foot-clone="selectedCampsForFunding.length > 0"
            >
              <template v-slot:cell(amount)="data">
                <div>
                  <div class="dollarsign">$</div>
                  <b-form-input
                    v-model="data.item.amount"
                    @blur="handleAmountInputLeave($event)"
                    @click="handleAmountInputClick($event)"
                    @keypress="onlyNumber($event)"
                    @keyup="handleLeadingDecimalEntry($event)"
                    style="background:white;border:none;"
                    type="text"
                  />
                </div>
              </template>
              <template
                v-if="fundTypeSelected.text == 'tcChurchReceipts' || fundTypeSelected.text == 'tcGideonCardReceipts'"
                v-slot:cell(count)="data"
              >
                <div>
                  <b-form-input
                    v-model="data.item.count"
                    @keypress="onlyNumber($event)"
                    style="background:white;border:none;"
                    type="text"
                  />
                </div>
              </template>
              <template v-slot:cell(key)="data">
                <div class="service-action-bar">
                  <div @click="deleteItem(data)">
                    <ServiceDelete />
                  </div>
                </div>
              </template>
              <template #foot(amount)="data">
                <div>
                  <div class="dollarsign font-weight-500" style="top:-3px">$</div>
                  <b-form-input class="line-total" v-model="lineItemTotal" disabled />
                </div>
              </template>
              <template #foot(count)="data">
                <div>
                  <b-form-input class="line-total" v-model="countTotal" disabled />
                </div>
              </template>
              <template #foot(camp_name)="data">
                <span class="text-right text-uppercase font-weight-bold">{{ translations.tcTotal }}</span>
              </template>
            </b-table>
            <div v-if="selectedCampsForFunding.length > 0">
              <div class="text-right">
                <label class="error-label" v-if="maxDonationExceeded">{{ translations.tcExceededMaxAmount }}</label>
              </div>
              <div class="text-right">
                <b-button variant="btn btn-tertiary" @click="commenceCleanUp">{{ translations.tcClear }}</b-button>
                <b-button
                  :disabled="maxDonationExceeded || remittanceTimeRemaining(remittanceProcessedTime) > 0"
                  class="ml-5"
                  variant="secondary"
                  id="addSummaryModal"
                  @click="prepareTheModal($event, 'addSummaryModal')"
                  target="_blank"
                  rel="noopener"
                >
                  {{ translations.tcSubmit }}
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <AddSummary
      :modalTitle="modalTitle"
      :remittanceKey="line.remittanceKey"
      :multicamp="multicamp"
      :remittanceDetailKey="line.remittanceDetailKey"
      :remittanceDate="line.remittanceDate"
      :i18n="translations.components"
      :translations="translations"
      @continueToPayment="processPayment($event)"
    />
    <div style="height: 40px">&nbsp;</div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, mapState } from 'vuex'
import AddSummary from '@/components/treasury-tools/add_summary'
import { alertMixin } from '@/mixins/alertMixin'
import { breakpoints } from '@/mixins/breakpoints'
import { modalMixin } from '@/mixins/modalMixin'
import { numericMixin } from '@/mixins/numericMixin'
import { remittanceMixin } from '@/mixins/remittanceMixin'
import SecurityBanner from '@/components/SecurityBanner'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import { staffUserMixin } from '@/mixins/staffUserMixin'
import { translationMixin } from '@/mixins/translationMixin'
import accountMixin from '@/mixins/accountMixin'
import NoBankDetails from '@/components/NoBankDetails'

export default {
  name: 'e503-M',
  mixins: [
    numericMixin,
    remittanceMixin,
    translationMixin,
    breakpoints,
    modalMixin,
    staffUserMixin,
    alertMixin,
    accountMixin
  ],
  props: {},
  data() {
    return {
      countTotal: 0,
      entryErrors: [],
      fields: ['camp_name', 'amount', { key: 'key', label: '' }],
      form: {
        state_association: '',
        full_name: '',
        member_number: '',
        sortBy: '0'
      },
      fundTypeSelected: {},
      line: {
        remittanceDate: '',
        remittanceKey: '',
        remittanceDetailKey: ''
      },
      modalTitle: '',
      multicamp: true,
      num: '-1',
      optionsSortTypes: [
        { value: '0', text: 'Camp Name' },
        { value: '1', text: 'Camp Number' }
      ],
      stateAssociations: [],
      secured_tile_view_controls: {
        treasury_state_e503M_check_cash_bulk_tile: '514e605c-a4f1-487b-988d-58987211dce3',
        treasury_camp_e503M_check_cash_bulk_tile: '2c870158-65d4-48d0-bae1-c0175706b22e'
      },
      selectedCamp: null,
      selectedCampsForFunding: [],
      summaryCounts: [],
      summaryLineItems: [],
      tally: 0.0,
      title: '',
      todaysDate: '',
      translations: {}
    }
  },
  components: {
    AddSummary,
    SecurityBanner,
    ServiceDelete,
    NoBankDetails
  },

  computed: {
    ...mapGetters({
      campList: 'campModule/campList',
      campListByCampNum: 'campModule/campListByCampNum',
      campByKey: 'campModule/campByKey',
      currentCamp: 'campModule/currentCamp',
      fundTypeOptions: 'remittanceModule/fundTypeOptions',
      iCanSee: 'user/iCanSee',
      stateConFundTypeOptions: 'remittanceModule/stateConFundTypeOptions',
      stateOrCamp: 'campModule/stateOrCamp',
      stateUndist: 'campModule/stateUndist',
      userDemograph: 'user/demograph',
      userId: 'user/userId'
    }),
    ...mapState({
      multicampPendingItems: state => state.remittanceModule.multicampPendingItems,
      remittanceProcessedTime: state => state.remittanceModule.remittanceProcessedTime
    }),
    allowPage() {
      return (
        (this.stateOrCamp === 's'
          ? this.iCanSee(this.secured_tile_view_controls.treasury_state_e503M_check_cash_bulk_tile)
          : this.iCanSee(this.secured_tile_view_controls.treasury_camp_e503M_check_cash_bulk_tile)) &&
        ((!!this.stateUndist && !!this.stateUndist.cmp_org_key) || this.isStaffMember)
      )
    },
    campLabel() {
      return this.stateOrCamp === 's' ? `${this.stateUndist.camp_name}` : `${this.currentCamp.text}`
    },
    campListSelect() {
      const selectedCampKeys = Array.from(new Set(this.selectedCampsForFunding.map(({ key }) => key)))
      let campListSorted = this.form.sortBy == '0' ? this.campList : this.campListByCampNum
      return campListSorted.filter(o => !selectedCampKeys.includes(o.value))
    },
    lineItemTotal() {
      this.tally = 0
      this.countTotal = 0
      this.selectedCampsForFunding.map(a => {
        a.amount = parseFloat(a.amount).toFixed(2) === 'NaN' ? 0 : a.amount
        this.tally += parseFloat(a.amount)
        a.count = parseInt(a.count) === 'NaN' ? 0 : a.count
        this.countTotal += parseInt(a.count)
      })
      return this.tally.toFixed(2)
    },
    stateOrCampKey() {
      return this.stateOrCamp === 's' ? this.stateUndist.cmp_org_key : this.currentCamp.key
    },
    maxDonationExceeded() {
      return this.maxAmtExceeded(this.tally.toFixed(2))
    },
    tableFields() {
      if (this.fundTypeSelected.text == 'tcChurchReceipts' || this.fundTypeSelected.text == 'tcGideonCardReceipts') {
        return ['camp_name', 'amount', 'count', { key: 'key', label: '' }]
      } else {
        return ['camp_name', 'amount', { key: 'key', label: '' }]
      }
    },
    showNoBalanceMsg() {
      if (!this.account.bnk_routing_number && this.stateOrCamp === 'c') {
        return true
      }
      return false
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await this.getAccount(this.homeCamp.key)
      if (this.stateOrCamp === 'c' && !!this.account.bnk_routing_number) {
        await this.page_load()
      }
      if (this.stateOrCamp === 's') {
        await this.page_load()
      }
    } catch (error) {
      console.error('Error during account retrieval or page load:', error)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      getFundTypes: 'remittanceModule/getFundTypes',
      processRemittance: 'remittanceModule/processRemittance',
      savePendingTransaction: 'remittanceModule/savePendingTransaction',
      setCurrentCampByKey: 'campModule/setCurrentCampByKey',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPresentationSummaryItems: 'remittanceModule/setPresentationSummaryItems',
      setSummaryForMulticampItems: 'remittanceModule/setSummaryForMulticampItems'
    }),

    commenceCleanUp() {
      this.selectedCampsForFunding = []
      if (this.fundTypeSelected.value != undefined) {
        document.getElementById(this.fundTypeSelected.value).checked = false
      }
      this.fundTypeSelected = {}
      this.entryErrors = 0
    },
    deleteItem(data) {
      const index = this.selectedCampsForFunding.findIndex(c => c.key == data.value)
      if (index => 0) {
        this.selectedCampsForFunding.splice(index, 1)
      }
    },

    handleAmountInputLeave(evt) {
      if (evt.target.value === '') {
        evt.target.value = '0.00'
      } else {
        evt.target.value = isNaN(evt.target.value) ? '0' : (evt.target.value * 1.0).toFixed(2)
      }
    },
    async handleCampSelect(key) {
      this.selectedCamp = null
      if (key) {
        let camp = await this.campByKey(key)
        this.selectedCampsForFunding.push({
          camp_name: '(' + camp.camp_number + ') ' + camp.camp_name,
          amount: 0,
          count: 0,
          key: key,
          fundTypeSelected: this.fundTypeSelected,
          countText: this.fundTypeSelected.countText,
          countField: this.fundTypeSelected.countField
        })
      }
    },
    async page_load() {
      try {
        this.form.stateAssociations = this.userDemograph.state
        this.form.fullName = this.userDemograph.first_name + ' ' + this.userDemograph.last_name
        this.form.memberNumber = this.userDemograph.member_number
        const today = new Date()
        const date = today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear()
        this.todaysDate = date
        await Promise.all([
          await this.getViewTranslations(),
          await this.getComponentTranslations('add-summary', 'tt-generic-verify')
        ]).then(results => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
        })
        this.optionsSortTypes[0].text = this.translations.tcCampName
        this.optionsSortTypes[1].text = this.translations.tcCampNumber
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
        if (this.remittanceTimeRemaining(this.remittanceProcessedTime) > 0) {
          await this.showRemittanceInProcessMessage()
        }
      }
    },
    async prepareTheModal(evt) {
      this.scrollUpToTop()
      this.summaryLineItems = []
      this.summaryCounts = []
      var countTitle = ''
      var fundTypeHasCounts =
        this.fundTypeSelected.text == 'tcChurchReceipts' || this.fundTypeSelected.text == 'tcGideonCardReceipts'
      this.selectedCampsForFunding.map(alpha => {
        countTitle = this.fundTypeSelected.countText
        this.summaryLineItems.push({
          camp_name: alpha.camp_name,
          camp_key: alpha.key, //camp key
          amount: alpha.amount, //amount
          order: alpha.camp_name.split(')')[1].trim(),
          field: this.fundTypeSelected.value,
          countText: this.fundTypeSelected.countText,
          countField: this.fundTypeSelected.countField,
          count: fundTypeHasCounts ? alpha.count : 0,
          rmdKey: alpha.rmd_key
        })
      })
      this.modalTitle = fundTypeHasCounts
        ? this.translations[this.fundTypeSelected.text] + ' / ' + this.translations[countTitle]
        : this.translations[this.fundTypeSelected.text]

      let errorMessage = ''
      if (Object.keys(this.fundTypeSelected).length === 0) {
        errorMessage = this.translations.tcNoFundSelected
      } else if (this.validateRemittance() === false) {
        errorMessage = this.translations.tcErrorInvalidValues
      } else if (this.summaryLineItems.length === 0) {
        errorMessage = this.translations.tcErrorDuringSave
      } else {
        this.setSummaryForMulticampItems(this.summaryLineItems)
        this.showModal(evt, 'addSummaryModal', 'summaryModal')
      }
      if (errorMessage.length > 0) {
        this.$swal({
          icon: 'error',
          text: errorMessage,
          confirmButtonText: this.translations.tcOk
        })
      }
    },
    async processPayment() {
      const remitKey = this.line.remittanceKey
      const rmtDate = this.formattedDate(new Date())
      this.commenceCleanUp()
      this.$swal({
        icon: 'success',
        text: this.translations.tcRemittanceProcessingMessage,
        confirmButtonText: this.translations.tcOk
      }).then(result => {
        this.$router.push({ path: `/officers/camp/treasury-tools/${this.stateOrCamp}` })
      })
      await this.savePendingTransaction({
        key: remitKey,
        date: rmtDate,
        rmd: this.remittanceDetailKey,
        multicamp: this.multicamp
      })
    },
    scrollUpToTop() {
      const elmnt = document.getElementById('nav')
      elmnt.scrollIntoView()
    },
    async showRemittanceInProcessMessage() {
      let timeRemaining = await this.remittanceTimeRemaining(this.remittanceProcessedTime)
      this.$swal({
        icon: 'error',
        text: this.translations.tcRemittanceInProcessMessage + timeRemaining + ' ' + this.translations.tcMinutes,
        confirmButtonText: this.translations.tcOk
      })
    },
    validateRemittance() {
      let campIsSelected = this.selectedCampsForFunding.length > 0
      let campsWithoutAmount = 0
      this.selectedCampsForFunding.map(cff => {
        if (cff.amount == 0 || isNaN(cff.amount)) campsWithoutAmount++
      })
      return campIsSelected && campsWithoutAmount == 0
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/settings.scss';

/*This setting is to hide the up/down arrows, in web browsers, on the number input*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.editing {
  background-color: #35b130;
  color: #ffffff;
  border-radius: 6px;
}

h5 {
  font-weight: 700;
}

h6 {
  font-weight: 700;
}

.dollarsign {
  position: relative;
  height: 0;
  left: -20px;
  font-size: 22px;
  top: 3px;
}

.error-label {
  color: red;
}

.i-tooltip {
  vertical-align: super;

  svg path {
    fill: #000 !important;
  }
}

.service-action-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    margin-right: 10px;

    &:hover {
      opacity: 0.6;
    }

    svg {
      cursor: pointer;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.section-break {
  margin-bottom: 30px;
}

#first-fund-group {
  margin-top: 30px;
  margin-left: 10px;
}

#second-fund-group {
  margin-left: 20px;

  @include breakpoint(md) {
    margin-left: 0px;
    border-left: none;
  }
}

#radio-group-1 {
  padding-top: 10px;
}

#radio-group-2 {
  margin-left: 25px;
  border-top: 1px solid grey;
  padding: 10px;

  @include breakpoint(md) {
    margin-left: 10px;
    padding-left: 0px;
  }
}

.remittance-date {
  text-align: right;

  @include breakpoint(sm) {
    text-align: left;
  }
}

.state-convention {
  column-count: 2;

  @include breakpoint(md) {
    column-count: 1;
  }
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.new-row {
  display: inline-block;
  margin-bottom: 10px;
}

.flex-left {
  width: 75%;
}

.flex-right {
  width: 25%;
}

.line-total {
  background: none;
  border: none;
  padding-top: 0;
  color: #747474;
  font-size: 18px;
}

.instruction {
  margin-bottom: 20px;
  text-align: center;
}

.numberCircle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 1px 9px;
  margin-right: 5px;
  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font-weight: bolder;
  font-size: 15px;
  display: inline-block;
}

.instruction-text {
  font-size: 20px;
  display: inline-block;
  font-weight: bolder;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: lightgrey !important;
}

.custom-control-inline {
  margin-right: 0px;
}
</style>
<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.col-form-label {
  text-align: center;
}

.selected-camps {
  th {
    color: #747474;
    font-weight: 700;
    border-top: none !important;
  }

  td + td {
    //did it this way to support old browsers
    width: 25% !important;

    input {
      border-radius: 5px;
    }

    @include breakpoint(sm) {
      width: 35% !important;
    }
  }

  td + td + td {
    width: 15% !important;
  }

  td + td + td + td {
    width: 10% !important;
  }

  tfoot {
    border-top: 5px double #747474;
  }
}

.sortBy {
  border: 1px solid grey;
  border-radius: 5px;

  .btn.btn-light {
    color: grey;
  }

  .btn-outline-light:not(:disabled):not(.disabled):active,
  .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: white;
    background-color: grey;
  }

  .btn.btn-outline:hover,
  .btn.btn-outline-light:hover {
    color: white;
    background-color: lightgray;
  }

  .btn-sm,
  .btn-group-sm > .btn {
    line-height: 0;
    font-weight: 600;
  }
}
</style>
